// SceneController.js

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { loadOBJ } from './utils/commonIO.js'
import vertexShader from './shaders/hologram/vertex.glsl'
import fragmentShader from './shaders/hologram/fragment.glsl'

const clock = new THREE.Clock()
const rotate = false

// Create shader material
const shaderMaterial = new THREE.ShaderMaterial({
  vertexShader,
  fragmentShader,
  uniforms:
    {
      uTime: new THREE.Uniform(0),
      uColor: new THREE.Uniform(new THREE.Color(0x0EA8A6))
    },
  transparent: true,
  side: THREE.DoubleSide,
  depthWrite: false,
  blending: THREE.AdditiveBlending
})

export class SceneController {
  #canvas
  #renderer
  #camera
  #controls
  #scene
  #mesh
  #sizes = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  constructor () {
    this.#canvas = document.querySelector('.scene')

    this.init()
  }

  async init () {
    // Scene
    this.#scene = new THREE.Scene()

    // Load models
    await this.loadModels()

    // Lights
    this.setLights()

    // Camera
    this.setCamera()

    // Renderer
    this.setRenderer()

    // Orbit controls
    this.setOrbitControls()

    // Set up events
    this.events()

    // Update
    this.update()
  }

  async loadModels () {
    try {
      this.#mesh = await loadOBJ('models/mesh.obj')

      // Apply the shader material to the mesh
      this.#mesh.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = shaderMaterial
        }
        this.#scene.add(this.#mesh)
      })
    } catch (error) {
      console.error('Error loading obj model:', error)
    }
  }

  setLights () {
    const ambientLight = new THREE.AmbientLight(0xffffff, 2.0) // Soft white light
    this.#scene.add(ambientLight)
  }

  setCamera () {
    this.#camera = new THREE.PerspectiveCamera(25, this.#sizes.width / this.#sizes.height, 0.1, 100)
    this.#camera.position.set(0, 3, 6)
  }

  setRenderer () {
    if (this.#canvas instanceof HTMLCanvasElement) {
      this.#renderer = new THREE.WebGLRenderer({
        canvas: this.#canvas,
        antialias: true
      })
      this.#renderer.setSize(window.innerWidth, window.innerHeight)
    } else {
      // Handle the case where canvas was not found or not an HTMLCanvasElement
      console.error('Canvas element not found or invalid:', this.#canvas)
    }
  }

  setOrbitControls () {
    this.#controls = new OrbitControls(this.#camera, this.#renderer.domElement)
    this.#controls.enableDamping = true
  }

  events () {
    window.addEventListener('resize', this.onWindowResize.bind(this), false)
  }

  /**
    * Renders the scene and updates controls (if available) in a loop using requestAnimationFrame.
    */
  update () {
    const elapsedTime = clock.getElapsedTime()

    // Update material
    shaderMaterial.uniforms.uTime.value = elapsedTime

    // Rotate
    if (rotate) this.applyRotation(elapsedTime)

    // Update controls
    if (this.#controls) this.#controls.update()

    this.#renderer.render(this.#scene, this.#camera)
    window.requestAnimationFrame(this.update.bind(this))
  }

  applyRotation (elapsedTime) {
    // Define the range of rotation in radians
    const maxRotationY = Math.PI / 8 // 22.5 degrees in radians
    const minRotationY = -Math.PI / 8 // -22.5 degrees in radians

    // Calculate the rotation angle using a sine function to oscillate between the min and max values
    const range = maxRotationY - minRotationY
    const rotationY = Math.sin(elapsedTime * 0.1) * range

    // Apply the rotation
    this.#mesh.rotation.y = rotationY
  }

  /**
    * Handles the window resize event.
    * Updates the width and height of the sizes object, recalculates the aspect ratio of the camera,
    * updates the camera's projection matrix, and resizes the renderer to match the new window dimensions.
    */
  onWindowResize () {
    this.#sizes.width = window.innerWidth
    this.#sizes.height = window.innerHeight
    this.#camera.aspect = this.#sizes.width / this.#sizes.height
    this.#camera.updateProjectionMatrix()
    this.#renderer.setSize(this.#sizes.width, this.#sizes.height)
  }
}
