import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

const objLoader = new OBJLoader()

export function loadOBJ (url) {
  return new Promise((resolve, reject) => {
    objLoader.load(
      url,
      (mesh) => {
        resolve(mesh) // Resolve the promise with the loaded mesh object
      },
      undefined,
      (error) => {
        reject(error) // Reject the promise with the error if loading fails
      }
    )
  })
}
